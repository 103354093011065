import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton';
import LinkRe from './LinkRe'
import BtnTools from './BtnTools'

const Projects = ({ props }) => {
  //console.log(props)
  return (
    props.proyects.map((item, index) => {
      return (
        <div key={index} className='grid  grid-flow-col gap-4 mt-10 proyectB'>
          <div className='row-span-3'>
            {item.title ? (
                <img
                  style={{
                    width: 120,
                    height: 120,
                  }}
                  alt={item.title}
                  src={item.image}
                  className='rounded-lg'
                />
              ) : (
                <Skeleton variant="rectangular" width={120} height={120} />
              )
            }

            <div className='mt-4 flex items-center gap-x-4 text-xs'>
              { }
            </div>
          </div>
          <div className='row-span-2'>
            <h2 className=' text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>{item.name}</h2>
            <p className='mt-5 leading-6 text-gray-600'> {item.description}</p>

            
            <div className='mt-4 flex items-center gap-x-4 text-xs flex-wrap'>
              {item.links.map((item, index) => {
                return (
                  <div className='flex-auto rounded w-3/12'>
                    
                      <LinkRe key={index} text={item.name} link={item.url}></LinkRe>
                
                  </div>
                )
              })}

            </div>

          </div>
        </div>
      )
    })

  )
}

Projects.propTypes = {}

export default Projects