import React from 'react'

export const Education = (props) => {
    return (
        props.props.education.map((item, index) => {
            return (
                <div key={index} >
                    <h3 className='mt-3 text-lg font-medium leading-6 text-gray-900 group-hover:text-gray-600'>
                        {item.name} - {item.type}
                    </h3>
                    <p className='mt-5 leading-6 text-gray-600 mt-0'>
                        {item.attributes.name} - ({item.attributes.start_date} - {item.attributes.end_date})
                    </p>
                </div>
            )
        })
    )
}
