import logo from './logo.svg';
import './App.css';
import SocialLogos from './components/SocialLogos';
import Experience from './components/Experience';
import Projects from './components/Projects';
import { apijsonData } from './model/Dataread';
import { useEffect, useState } from 'react';
import { Education } from './components/Education';

function App() {
  const [data, setData] = useState(null);
  const [experience, setExperience] = useState([]);

  useEffect(() => {
    apijsonData().then(data => {
      setData(data);  // Set the fetched data into the state
    });

  }, []);


  return (
    <div className="mx-auto min-h-screen max-w-screen-2xl px-6  md:px-12 lg:px-24  min-h-screen max-w-screen-xl px-6   md:px-12  lg:px-24  ">
      <div className='lg:flex lg:justify-between lg:gap-10'>
        <header className="header shadow-r lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24">
          <div className='pl-10 cont1name' >
            <h1 className='fjalla-one-regular text-5xl'>Carlos Orjuela</h1>
            <h2 className='mt-3 text-2xl font-semibold '>Multimedia Engineer - Developer</h2>
            
            <ul className="list">
              <li className='nameMenu'>
                  
              </li>
              <li>
                <a href='#about'>
                  <span data-name="🤖" className='menu' >
                    About
                  </span>
                </a>
              </li>
              <li>
              <a href='#expe'>
                <span data-name="🦾" className='menu'>

                  Experience
                </span>
                </a>
              </li>
              <li>
              <a href='#proje'>
                <span data-name="👨‍💻" className='menu'>

                  Projects
                </span>
                </a>
              </li>
              <li>
              <a href='#edu'>
                <span data-name="👨‍💻" className='menu'>

                  Education
                </span>
                </a>
              </li>
            </ul>
            <div>
              <SocialLogos />

            </div>
          </div>
        </header>
      
        <main className=' lg:w-1/2 lg:py-24'>
          <div className='grid grid-cols-1 gap-4 scrolling-box'>
            <div className='about scrollSm' id='about'>
              <p className='mb-4 firstSpa'>
                Multimedia Engineer with experience in the complete lifecycle of IT projects and a strong background in coding, particularly in web applications adopting JavaScript, HTML5, CSS3 and JAVA technologies using different IDEs and frameworks, like React and Redux.</p>
              <p className='mb-4'> Expertise and capabilities in the definition, design, construction, evaluation and maintenance of web sites and experience in the development of agile methodologies.</p>
              <p>Strong sense of responsibility, ease of adaptation, creativity, commitment,
                Excellent problem solving and team-working skills, with an interest in a lifelong learning with a professional and personal development.</p>


            </div>
            <div className="border-t border-gray-200 scrollSm" id='expe'>
              {data == null ?
                "loading " : <Experience props={data} />
              }

            </div>
            <div className="border-t border-gray-200 scrollSm" id='proje'>
              {data == null ?
                "loading " : <Projects props={data} />
              }
            </div>
            <div className="border-t border-gray-200 scrollSm" id='edu'>
              <h2 className='mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>
              Education
              </h2>
              {data == null ?
                "loading " : <Education props={data} />
              }
            </div>

          </div>
        </main >
      </div >
    </div >
  );
}

export default App;
